import Vue from 'vue'
import camelCaseKeys from 'camelcase-keys-deep'
import JobsIndex from '../../../vue_widgets/mobile/service_titan/jobs/index'

Styxie.Initializers.MobileServiceTitanJobs = {
  index: (params) => {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-service-titan-jobs-index'),
        render: (h) => h(JobsIndex, { props: camelCaseKeys(params) })
      })
    })
  }
}
