<template>
  <div>
    <p class="mb-0 pt-3 pl-3">
      <b>Warning:</b> Posting fake reviews is illegal. Violators may face
      <a target="_blank"
        href="https://www.ftc.gov/news-events/news/press-releases/2021/10/ftc-puts-hundreds-businesses-notice-about-fake-reviews-other-misleading-endorsements">
        fines exceeding $40,000</a>.
    </p>
    <div class="d-flex align-items-center flex-row p-3 gap-5">
      <div class="w-50">
        <div class="form-field mb-2">
          <label class="mb-1">
            Rating<span class="text-danger">*</span>
          </label>

          <VueMultiselect
            v-model="internalReview.rating"
            :options="Object.keys(STARS_OPTIONS)"
            :max-height="540"
            :searchable="false"
            :allow-empty="false"
            placeholder="Select rating">
            <template slot="option" slot-scope="{ option }">{{ STARS_OPTIONS[option] }}</template>
            <template slot="singleLabel" slot-scope="{ option }">{{ STARS_OPTIONS[option] }}</template>
          </VueMultiselect>
        </div>

        <div class="form-field mb-2">
          <label class="mb-1">
            Reviewer<span class="text-danger">*</span>
          </label>
          <input v-model="internalReview.reviewer" type="text" placeholder="Reviewer's name">
        </div>

        <div class="form-field mb-2">
          <label class="mb-1">
            Comment<span class="text-danger">*</span>
          </label>
          <textarea v-model="internalReview.comment" @input="adjustHeight" placeholder="Review" />
        </div>

        <div class="form-field mb-2">
          <label class="mb-1">
            Reply
          </label>
          <textarea v-model="internalReview.reviewReply" @input="adjustHeight" placeholder="Reply" />
        </div>

        <div class="form-field mb-2">
          <label class="mb-1">
            Platform Name<span class="text-danger">*</span>
          </label>
          <input v-model="internalReview.freeFormPlatform" type="text" placeholder="Review platform name">
        </div>

        <div class="form-field">
          <label class="mb-1">
            Review Link
          </label>
          <input v-model="internalReview.freeFormLink" type="text" placeholder="Review link">
        </div>
      </div>

      <div v-if="verifyInternalReview" class="w-25">
        <CardReview :review="gmbReviewFormat" class="moment-reviews__item" />
      </div>
    </div>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'

import CardReview from './card_review'

const adjustTextAreaHeight = (textarea) => {
  textarea.style.height = `${textarea.scrollHeight}px`
}

export default {
  components: {
    VueMultiselect,
    CardReview
  },
  props: {
    review: { type: Object }
  },
  data() {
    return {
      internalReview: {
        rating: this.review?.rating || 'five',
        reviewer: this.review?.reviewer || '',
        comment: this.review?.comment || '',
        reviewReply: this.review?.reviewReply || '',
        freeFormPlatform: this.review?.freeFormPlatform || '',
        freeFormLink: this.review?.freeFormLink || ''
      }
    }
  },
  computed: {
    verifyInternalReview() {
      return this.internalReview.rating.length &&
        this.internalReview.reviewer.length &&
        this.internalReview.comment.length
    },
    gmbReviewFormat() {
      const formatted = {
        rating: this.STARS_OPTIONS[this.internalReview.rating],
        reviewer: { displayName: this.internalReview.reviewer },
        comment: this.internalReview.comment,
        freeFormPlatform: this.internalReview.freeFormPlatform,
        freeFormLink: this.internalReview.freeFormLink
      }

      if (this.internalReview.reviewReply.length) {
        formatted.reviewReply = { comment: this.internalReview.reviewReply }
      }

      return formatted
    }
  },
  methods: {
    adjustHeight(e) {
      adjustTextAreaHeight(e.target)
    }
  },
  created() {
    this.STARS_OPTIONS = {
      one: 1,
      two: 2,
      three: 3,
      four: 4,
      five: 5
    }
  },
  mounted() {
    this.$emit('selectFreeFormReview', { ...this.internalReview })
    this.$el.querySelectorAll('textarea').forEach(adjustTextAreaHeight)
  },
  watch: {
    internalReview: {
      handler(newVal) {
        this.$emit('selectFreeFormReview', { ...newVal })
      },
      deep: true
    }
  }
}
</script>
