<template>
  <div>
    <VueSlimTable
      ref="table"
      :source="tableSource"
      :columns="columns"
      :per-page="perPage"
      class="responsive-table borderless-table m-0">
      <template #row="{ row }">
        <TableRow :row="row"
          @reload="reload" />
      </template>
      <template #pagination>
        <Pagination :page="currentPage" :per-page="perPage" :total-count="totalRecords" @updatePage="updatePageNumber" />
      </template>
    </VueSlimTable>
  </div>
</template>

<script>
import Pagination from 'vue_widgets/components/pagination'
import TableRow from './table_row'

export default {
  components: { Pagination, TableRow },
  props: {
    baseUrl: { type: String, required: true }
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      totalRecords: 0
    }
  },
  created() {
    this.columns = [
      { title: 'ID', key: 'id', orderable: false },
      { title: 'Summary', key: 'summary', orderable: false }
    ]
  },
  methods: {
    tableSource(prms) {
      this.totalRecords = 0
      const params = {
        ...prms,
        page: this.currentPage
      }

      return axios.get(
        this.baseUrl,
        {
          params,
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
        }
      )
        .then((res) => {
          this.totalRecords = res.headers['total-count']
          return res.data
        })
    },
    updatePageNumber(page) {
      this.currentPage = page
      this.reload()
    },
    reload() {
      this.$refs.table?.refetch()
    }
  }
}
</script>
