<template>
  <div class="d-flex flex-column gap-1 mb-3">
    <div class="form-field">
      <span>Locations</span>
      <CustomSelect :option-data="field"
        v-model="selectedLocations"
        @select="addLocation"
        @remove="removeLocation" />
    </div>
    <Connection v-for="connection in locationsConnections" :connection="connection" :key="connection.id" @removeConnection="removeConnection" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import snakeCaseKeys from 'snakecase-keys'
import CustomSelect from 'vue_widgets/components/filter/select'
import Connection from './connection'

export default {
  computed: mapState(['locationsConnections', 'apiOrgLocationsPath']),
  components: { Connection, CustomSelect },
  data() {
    return {
      selectedLocations: null,
      field: {
        trackBy: 'remoteId',
        label: 'name',
        searchable: true,
        internalSearch: false,
        showLabels: false,
        allowEmpty: true,
        maxHeight: 540,
        multiple: true,
        placeholder: '',
        options: [],
        sourceOptions: this.getLocations
      }
    }
  },
  methods: {
    ...mapActions(['addLocation']),
    ...mapMutations(['removeLocation']),
    // We need such an enormous page size on initial load to display all selected location correctly
    // When select iterates over pages on scroll/search that's ok to cleanup perPage and back to defaults
    getLocations(params = { perPage: 200 }) {
      return axios.get(
        `${this.apiOrgLocationsPath}/index_for_select`,
        {
          params,
          paramsSerializer(json) {
            return qs.stringify(snakeCaseKeys(json))
          }
        }
      )
        .then((res) => {
          const locations = res.data.map(({ id, obfuscatedId, ...orgLoc }) => ({
            ...orgLoc,
            remoteId: id,
            orgLocationId: obfuscatedId
          }))
          if (this.selectedLocations === null) {
            this.selectedLocations = locations.filter(({ remoteId }) => this.locationsConnections.find((connections) => connections.remoteId === remoteId))
          }

          return { data: locations }
        })
    },
    removeConnection(connection) {
      this.removeLocation(connection)
      this.selectedLocations = this.selectedLocations.filter(({ remoteId }) => remoteId !== connection.remoteId)
    }
  }
}
</script>
