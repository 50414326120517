<template>
  <tr :class="{selected: keyword.selected}">
    <td column-caption="Keyword">
      <div v-if="!isPublic" class="d-flex align-items-center">
        <Checkbox :value="row.selected" @input="onRowCheckToggle" />
        <a class="text-break" :href="keywordEditUrl">{{ keyword.searchTerm }}</a>
        <div v-if="keywordsCommon.forceCheckEnabled" class="ml-auto">
          <div v-if="keywordLoadingStatus"
            data-toggle="tooltip"
            data-placement="right"
            ref="triggeredManualRanks"
            :title="tooltipText">
            <i class="far fa-spinner fa-pulse" />
          </div>
        </div>
      </div>
      <template v-else>
        {{ keyword.searchTerm }}
      </template>
    </td>
    <td column-caption="local Rank">
      <KeywordRanks v-if="keyword.local"
        :ranks="keyword.data && keyword.data.local"
        :serp-html="!isPublic ? keyword.localSerpHtml : ''"
        :obfuscated-id="keyword.obfuscatedId"
        @deleteRank="deleteRank"
        type="local" />
      <BtnAdd v-else-if="!isPublic"
        :disabled="tableLocked"
        :rank-type="'local'"
        :keyword="keyword"
        @cloneKeyword="cloneKeyword" />
      <template v-else>
        Skipped
      </template>
    </td>
    <td column-caption="Organic Rank">
      <KeywordRanks v-if="keyword.organic"
        :ranks="keyword.data && keyword.data.organic"
        :serp-html="!isPublic ? keyword.organicSerpHtml : ''"
        :obfuscated-id="keyword.obfuscatedId"
        @deleteRank="deleteRank"
        type="organic" />
      <BtnAdd v-else-if="!isPublic"
        :disabled="tableLocked"
        :rank-type="'organic'"
        :keyword="keyword"
        @cloneKeyword="cloneKeyword" />
      <template v-else>
        Skipped
      </template>
    </td>
    <td column-caption="Organic Mobile Rank">
      <KeywordRanks v-if="keyword.organicMobile"
        :ranks="keyword.data && keyword.data.organic_mobile"
        :serp-html="!isPublic ? keyword.organicMobileSerpHtml : ''"
        :obfuscated-id="keyword.obfuscatedId"
        @deleteRank="deleteRank"
        type="organic_mobile" />
      <BtnAdd v-else-if="!isPublic"
        :disabled="tableLocked"
        :rank-type="'organic_mobile'"
        :keyword="keyword"
        @cloneKeyword="cloneKeyword" />
      <template v-else>
        Skipped
      </template>
    </td>
    <td column-caption="Last Checked">
      {{ syncedAt }}
    </td>
    <td :class="{ 'text-right': isPublic }" column-caption="Check Frequency">{{ frequency[keyword.checkFrequency] }}</td>
  </tr>
</template>

<script>
import moment from 'moment'
import Checkbox from '../../components/checkbox'
import KeywordRanks from './keyword_ranks'
import BtnAdd from './keyword_add_btn'

export default {
  components: { KeywordRanks, Checkbox, BtnAdd },
  props: {
    row: { type: Object, required: true },
    tableLocked: { type: Boolean, default: false },
    keywordLoadingStatus: { type: Boolean, required: true },
    keywordsCommon: { type: Object, default: () => ({}) },
    isPublic: { type: Boolean, default: false }
  },
  data() {
    return {
      keyword: {},
      selected: false
    }
  },
  created() {
    this.frequency = {
      two_days: 'Two days',
      one_week: 'One week',
      two_weeks: 'Two weeks',
      one_month: 'One month'
    }
    this.timeIntervals = ['month', 'day', 'hour', 'minute', 'second']
    this.keyword = this.row
    this.tooltipText = '<p class="text-left font-size-14 m-0">Rank calculation in progress.</p>'
  },
  mounted() {
    $(this.$refs.triggeredManualRanks).tooltip({
      delay: { show: 50, hide: 200 },
      container: this.$refs.triggeredManualRanks,
      html: true
    })
  },
  beforeDestroy() {
    $(this.$refs.triggeredManualRanks).tooltip('dispose')
  },
  computed: {
    keywordEditUrl() {
      return `${window.location.pathname}/${this.keyword.obfuscatedId}`
    },
    syncedAt() {
      if (!this.keyword.syncedAt) return 'Never'
      const curr = moment.utc()
      const last = moment.utc(this.keyword.syncedAt)

      let syncedAt = ''
      this.timeIntervals.forEach((interval) => {
        if (!syncedAt.length) {
          const diff = curr.diff(last, `${interval}s`)
          if (diff) {
            syncedAt = `${this.pluralizeDiff(`${diff} ${interval}`, diff)} ago`
          }
        }
      })

      return syncedAt
    }
  },
  methods: {
    onRowCheckToggle(bool) {
      this.$emit('onRowCheckToggle', { bool: bool, keywordId: this.keyword.id })
    },
    pluralizeDiff(phrase, diff) {
      return diff > 1 ? `${phrase}s` : phrase
    },
    cloneKeyword(type) {
      if (type === 'organic_mobile') type = 'organicMobile'
      this.keyword[type] = true
    },
    deleteRank(type) {
      this.keyword[type] = false
      if (!['local', 'organic', 'organicMobile'].find((rank) => this.keyword[rank])) {
        this.$emit('refetch')
      }
    }
  }
}
</script>
