<template>
  <div class="geobooster-widgets-edit">
    <a ref="backLink" class="geobooster-widgets-edit__back" href="/gb/widgets">
      <i class="far fa-chevron-left" />
      <span>Back to the widgets list</span>
    </a>
    <Tabs
      ref="tabs"
      class="geobooster-widgets-edit__tabs"
      :list="tabs.list"
      :active-tab="tabs.activeIndex"
      :disabled-tabs="disabledTabs"
      @setActiveTab="setActiveTab" />
    <div
      class="geobooster-widgets-edit__wrapper"
      :style="{ height: wrapperHeight }">
      <div
        ref="forms"
        class="geobooster-widgets-edit__forms scroll ver"
        :class="stepAnimationClasses">
        <div
          class="geobooster-widgets-edit__list">
          <Collapse>
            <div v-if="errors.length">
              <div class="geobooster-widgets-edit__errors">
                <p v-for="(error, errorIndex) in errors"
                  :key="`error${errorIndex}`"
                  @click="setActiveTab(error.step)">
                  {{ error.text }}
                </p>
              </div>
            </div>
          </Collapse>
          <SettingsStep
            v-if="shouldShow('settings')"
            class="geobooster-widgets-edit__form"
            :slider-view-is-selected="steps.appearance.momentListLayout.value === 'slider'"
            :fields="steps.settings"
            @triggerValidate="validateForms" />
          <BusinessesStep
            class="geobooster-widgets-edit__form"
            v-if="shouldShow('business')"
            :fields="steps.business"
            :active-page-filters="steps.filters"
            @updateBusinesses="updateBusinessesSelect" />
          <AppearanceStep
            class="geobooster-widgets-edit__form"
            v-if="shouldShow('appearance')"
            :fields="steps.appearance"
            @setError="setError"
            @changeTheme="changeTheme"
            @triggerValidate="validateForms" />
          <FiltersStep
            class="geobooster-widgets-edit__form"
            v-if="shouldShow('filters')"
            :fields="steps.filters.fields"
            :filters="steps.filters.filters"
            :active-page-filter="activePageFilter"
            :active-page-filter-index="steps.filters.activePageFilterIndex"
            :last-selected-index="steps.filters.lastSelectedPageFilterIndex"
            @updatePath="updatePagePath"
            @setActivePageFilterIndex="setActivePageFilterIndex"
            @updateFilters="setPageFilters"
            @removePageFilter="removePageFilter"
            @addNewPageFilter="addNewPageFilter"
            @triggerValidate="validateFilters"
            @setLastSelectedPageFilterIndex="setLastSelectedPageFilterIndex" />
          <EmbedStep
            class="geobooster-widgets-edit__form"
            v-if="shouldShow('embedCode')"
            :configuration="widgetConfiguration"
            :widget-src="content.widgetSrc" />
        </div>
      </div>
      <div class="geobooster-widgets-edit__buttons fixed" :style="buttonsCss">
        <button
          class="geobooster-widgets-edit__button geobooster-widgets-edit__button--save btn btn--rem btn-primary"
          @click="saveConfig">
          <i v-if="isLoading" class="far fa-spinner-third fa-spin" />
          <i v-else-if="isSaved" class="far fa-check" />
          <i v-else class="far fa-save" />
          <span>Save</span>
        </button>
      </div>
      <div class="geobooster-widgets-edit__preview">
        <WidgetPreview
          v-if="content.widgetSrc"
          ref="previewComponent"
          :config="widgetConfiguration"
          :widget-src="content.widgetSrc"
          :active-filter="previewPageFilter"
          :last-active-filter="steps.filters.lastSelectedPageFilterIndex" />
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import camelcase from 'camelcase'
import snakeCase from 'to-snake-case'

import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys-deep'
import Tabs from '../../components/reusable_tabs'
import WidgetPreview from './geobooster_widgets_preview'
import Collapse from './blanks/collapse'

// steps mixins
import settingsStepMixin from './mixins/steps/settingsStepMixin'
import businessStepMixin from './mixins/steps/businessStepMixin'
import appearanceStepMixin from './mixins/steps/appearanceStepMixin'
import filtersStepMixin from './mixins/steps/filtersStepMixin'
import embedCodeStepMixin from './mixins/steps/embedCodeStepMixin'

export default {
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  mixins: [
    settingsStepMixin,
    businessStepMixin,
    appearanceStepMixin,
    filtersStepMixin,
    embedCodeStepMixin
  ],
  components: {
    Tabs,
    WidgetPreview,
    Collapse
  },
  data: () => ({
    isLoading: false,
    isSaved: false,
    tabs: {
      activeIndex: 0,
      list: []
    },
    texts: {
      SETTINGS_TEXT: 'Settings',
      APPEARANCE_TEXT: 'Appearance',
      BUSINESS_TEXT: 'Business',
      FILTERS_TEXT: 'Filters',
      EMBED_CODE_TEXT: 'Embed code'
    },
    wrapperHeight: null,
    stepAnimationClasses: '',
    errors: [],
    formsResizeObserver: null,
    containerResizeObserver: null,
    animationDelay: 150,
    buttonsCss: {
      left: null,
      width: null,
      position: null
    }
  }),
  mounted() {
    // prevent wrong/broken/unconfigured google api keys from sneaking to other pages
    document.body.setAttribute('data-turbolinks', false)

    this.tabs.list = [
      this.texts.SETTINGS_TEXT,
      this.texts.BUSINESS_TEXT,
      this.texts.APPEARANCE_TEXT,
      this.texts.FILTERS_TEXT,
      this.texts.EMBED_CODE_TEXT
    ]

    this.setInitialValues()

    setTimeout(() => {
      this.setWrapperHeight()
    })

    window.addEventListener('resize', this.setWrapperHeight)

    this.formsResizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return
        }

        this.setWrapperHeight()
      })
    })

    this.formsResizeObserver.observe(this.$refs.forms)

    this.containerResizeObserver = new ResizeObserver((e) => {
      const remMultiplier = parseFloat(window.getComputedStyle(document.querySelector('html')).fontSize)
      e.forEach(() => {
        this.buttonsCss.width =
          `${this.$refs.forms.getBoundingClientRect().width + 2.5 * remMultiplier - 2 * remMultiplier}px`
      })
    })

    this.containerResizeObserver.observe(document.querySelector('.main-content'))
  },
  destroyed() {
    window.removeEventListener('resize', this.setWrapperHeight)
    this.formsResizeObserver.disconnect()
    this.containerResizeObserver.disconnect()
  },
  methods: {
    setActiveTab(newIndex) {
      // out of bounds checking
      if (
        newIndex !== this.tabs.activeIndex &&
        newIndex >= 0 && newIndex < this.tabs.list.length &&
        typeof newIndex !== 'undefined'
      ) {
        const direction = newIndex - this.tabs.activeIndex > 0 ? 1 : 0

        this.setActivePageFilterIndex(null)

        const timeout = this.tabsMap.filters === this.tabs.activeIndex ? this.animationDelay * 2 : 0

        setTimeout(() => {
          this.animateTabChange(direction)

          setTimeout(() => {
            this.tabs.activeIndex = newIndex
          }, this.animationDelay)
        }, timeout)
      }
    },
    animateTabChange(direction) {
      const modifiers = [
        'left',
        'right'
      ]

      const primaryModifier = modifiers[direction]
      const secondaryModifier = modifiers.find((modifier) => modifier !== primaryModifier)

      this.stepAnimationClasses = `geobooster-widgets-edit__forms--fade-${secondaryModifier}`

      setTimeout(() => {
        this.stepAnimationClasses = `geobooster-widgets-edit__forms--fade-${primaryModifier} geobooster-widgets-edit__forms--no-transition `

        setTimeout(() => {
          this.stepAnimationClasses = ''
        }, 50)
      }, this.animationDelay)
    },
    shouldShow(key) {
      return this.tabsMap[key] === this.tabs.activeIndex
    },
    setInitialValues() {
      const {
        businesses, countries, widget: initialConfig
      } = this.content

      // settings step initials
      this.steps.settings.name.value = initialConfig.name
      this.steps.settings.googleApiKey.value = initialConfig.googleApiKey ?? ''
      this.steps.settings.googleMapId.value = initialConfig.googleMapId ?? ''
      if (initialConfig.mapHeight) {
        this.steps.settings.mapHeight.value = initialConfig.mapHeight
      }
      this.steps.settings.zoom.value = initialConfig.zoom ?? ''
      this.steps.settings.momentsPerPage.value = initialConfig.momentsPerPage ?? ''
      this.steps.settings.showCustomer.value = initialConfig.showCustomer ?? true
      this.steps.settings.showAddressDisclamer.value = initialConfig.showAddressDisclamer ?? false
      this.steps.settings.reviewAuthorStub.value = initialConfig.reviewAuthorStub ?? ''
      this.steps.settings.hideReviewAuthor.value = initialConfig.reviewAuthorStub ? 'custom' : initialConfig.hideReviewAuthor

      // businesses step initials

      this.steps.business.businesses.values = [
        ...businesses.map(({
          placeId, name, id, address, storeCode
        }) => {
          const description = []

          if (address) {
            description.push(address)
          } else {
            description.push('Service Area Business')
            description.push(`Place id: "${placeId}"`)
          }

          description.push(storeCode)

          return {
            text: name,
            value: id,
            placeId,
            address,
            description
          }
        })
      ]

      this.steps.business.selectedBusinesses = initialConfig.businesses.map((business) => business.obfuscatedId)
      this.steps.business.initialBusinessesIds = [...this.steps.business.selectedBusinesses]

      // appearance step initials
      const keysToIgnore = ['font', 'theme', 'mapMarkerIcon', 'customMapMarkerIcon']

      this.steps.appearance.mapMarkerIcon.value = initialConfig.mapMarkerIcon
      this.steps.appearance.customMapMarkerIcon.value = initialConfig.customMapMarkerIcon
      this.steps.appearance.showCustomMapMarkerWrapper.value = initialConfig.showCustomMapMarkerWrapper

      Object.keys(this.steps.appearance).forEach((key) => {
        if (!keysToIgnore.includes(key)) {
          const field = this.steps.appearance[key]
          const initialConfigValue = initialConfig[key]

          if (initialConfigValue) {
            field.value = initialConfigValue
          } else if (field.defaultValue) {
            field.value = field.defaultValue
          }
        }
      })

      this.steps.filters.fields.country.values = countries
      this.setCategories()

      let { filters } = initialConfig
      if (!filters || typeof filters === 'object' && Object.keys(filters).length === 0) {
        filters = []
      }

      this.steps.filters.filters = filters.map(({
        pagePath, categories, cities, administrativeAreas, country
      }) => ({
        pagePath: pagePath ?? '',
        categories: categories ?? [],
        cities: cities ?? [],
        administrativeAreas: administrativeAreas ?? [],
        country: country ?? '',
        error: false
      }))

      if (!this.steps.filters.filters.find((filter) => filter.pagePath === '*')) {
        this.addDefaultFilter()
      }
    },
    setError({ error, field, errorText }) {
      field.error = error
      field.errorText = errorText
    },
    async saveConfig() {
      if (!this.validateForms() || !this.validateFilters()) {
        return
      }

      this.isLoading = true

      const { theme, ...widgetConfiguration } = this.widgetConfiguration

      const formData = new FormData()

      const snakecasedWidgetConfiguration = snakecaseKeys({
        ...widgetConfiguration,
        ...theme,
        name: this.steps.settings.name.value.trim()
      })

      const excludedKeys = new Set(['custom_map_marker_icon'])
      // If we want to upload a file with the entire config - the only way is to use formdata
      const convertWidgetConfigToFormData = (object = snakecasedWidgetConfiguration, basePath = 'mobile_widget') => {
        const isArray = Array.isArray(object)
        Object.keys(object).forEach((key) => {
          if (excludedKeys.has(key)) return

          const field = object[key]
          const path = `${basePath}[${isArray ? '' : key}]`
          if (field && (Array.isArray(field) || typeof field === 'object')) {
            convertWidgetConfigToFormData(field, path)
            return
          }

          formData.append(path, field ?? '')
        })
      }

      convertWidgetConfigToFormData()

      const { customMapMarkerIcon } = this.widgetConfiguration

      // Well, i guess i kinda need to explain the cruel magic we have to do over here.
      // When we send all our config to save, we also need to ALWAYS send a FILE (binary).
      // Object with url will not do the job.
      // If we selected new image, fetch will instantly return binary.
      // If we didn't touch the image, it will re-create file object from url that we had initially
      // and re-send it to the server. Its not by any means pretty, but we do/did the same for moments,
      // so i decided to do the same over here

      if (customMapMarkerIcon?.changed && customMapMarkerIcon?.url) {
        const response = await fetch(customMapMarkerIcon.url, { mode: 'no-cors' })
        const data = await response.blob()
        const metadata = { type: data.type }
        const ext = data.type.split('/')[1]
        const file = new File([data], `${(new Date()).getTime()}.${ext}`, metadata)
        formData.append('mobile_widget[custom_map_marker_icon]', file)
      } else if (!customMapMarkerIcon?.url) {
        formData.append('mobile_widget[custom_map_marker_icon]', null)
      }

      axios.put(
        `/gb/ajax/widgets/${this.content.widget.id}`,
        formData
      )
        .then((response) => {
          setTimeout(() => {
            const data = camelcaseKeys(response.data)

            if (this.steps.settings.googleApiKeyInfo.show) {
              window.location.reload()
            }

            this.steps.appearance.customMapMarkerIcon.value = data.customMapMarkerIcon

            this.isSaved = true
            const newBusinessIds = data.businesses
            const { initialBusinessesIds } = this.steps.business

            const businessesLengthsAreDifferent = initialBusinessesIds.length !== newBusinessIds.length

            const businessesElementsAreDifferent =
              businessesLengthsAreDifferent ||
              !newBusinessIds.reduce((acc, next) => acc && initialBusinessesIds.includes(next.obfuscatedId), true)

            this.$refs.previewComponent.refetchPreviewMoments(this.widgetConfiguration)

            if (businessesElementsAreDifferent) {
              this.steps.business.initialBusinessesIds = newBusinessIds
            }
          }, 1500)
        })
        .catch((error) => {
          if (error.response.status === 422 && error.response.data) {
            this.setErrors(error.response.data.errors)
          } else if (error.response.status === 404) {
            toastr.error('Config not found')
          } else {
            this.setErrors({ Error: ['Something wrong happened here']})
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false
          }, 1500)
        })
    },
    setWrapperHeight() {
      const rect = this.$refs.forms.getBoundingClientRect()
      this.wrapperHeight = `${window.innerHeight - rect.top}px`
    },
    validateForms() {
      const errors = {}

      Object.keys(this.steps).forEach((stepName) => {
        const stepObject = this.steps[stepName]

        Object.keys(stepObject).forEach((fieldName) => {
          const fieldObject = stepObject[fieldName]
          const dependentValidationFieldNames = fieldObject?.validationFields ?? []
          const dependentFields = {}
          dependentValidationFieldNames.forEach((dependentFieldName) => {
            dependentFields[dependentFieldName] = stepObject[dependentFieldName]
          })

          if (!fieldObject) {
            return
          }

          const { validators } = fieldObject

          if (validators && Object.keys(validators).length > 0) {
            Object.keys(validators).forEach((validatorName) => {
              const validator = validators[validatorName]
              const { error, errorMessage } = validator(fieldObject.value, dependentFields)

              if (error) {
                if (!errors[snakeCase(fieldName)]) {
                  errors[snakeCase(fieldName)] = []
                }

                errors[snakeCase(fieldName)].push(errorMessage)
              }
            })
          }
        })
      })

      const valid = Object.keys(errors).length === 0

      this.setErrors(errors)

      if (!valid) {
        this.$refs.forms.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }

      return valid
    },
    setErrors(errors, overrideStep = null) {
      const errorsArray = []

      Object.keys(errors).forEach((key) => {
        const errorObject = {}
        const camelCasedKey = camelcase(key)
        const errorMessage = errors[key]
        let fieldName = key.split('_').join(' ')
        fieldName = `${fieldName.charAt(0).toUpperCase()}${fieldName.substring(1, fieldName.length)}`

        const formattedErrorMessage = errorMessage[0] ?? 'Unknown error'

        errorObject.text = `${fieldName}: ${formattedErrorMessage}`

        const relatedStepName = Object.keys(this.steps).find((step) => Object.keys(this.steps[step]).includes(camelCasedKey))

        if (relatedStepName) {
          const relatedFieldName = Object.keys(this.steps[relatedStepName]).find((field) => field === camelCasedKey)

          if (relatedFieldName) {
            const relatedField = this.steps[relatedStepName][relatedFieldName]

            relatedField.error = true
            relatedField.errorText = formattedErrorMessage
            errorObject.step = this.tabsMap[relatedStepName]
          }
        }

        if (overrideStep) {
          errorObject.step = overrideStep
        }

        errorsArray.push(errorObject)
      })

      this.errors = errorsArray
    },
    // if we well change our logic of selecting business as we wanted (allow user to change business for each page), this logic will be deprecated
    setCategories() {
      const allCategories = this.content.categories
      const savedCategories = {}
      const businessIds = new Set(this.widgetConfiguration.business_ids)

      allCategories.forEach(({ title, businessId }) => {
        if (!businessIds.has(businessId)) return

        if (!savedCategories[title]) {
          savedCategories[title] = {
            value: title,
            text: title,
            businessIds: [businessId]
          }

          return
        }

        savedCategories[title].businessIds.push(businessId)
      })

      this.steps.filters.fields.categories.values = Object.keys(savedCategories).map((categoryTitle) => savedCategories[categoryTitle])
    },
    eraseIncorrectCategories() {
      // yes this is utterly awful
      this.steps.filters.filters.forEach((pageFilter) => {
        pageFilter.categories = pageFilter.categories.filter((selectedCategory) => {
          const categoryCanBeSelected = this.steps.filters.fields.categories.values.find((category) => category.text === selectedCategory)
          return categoryCanBeSelected
        })
      })
    }
  },
  computed: {
    tabsMap() {
      return {
        settings: this.tabs.list.indexOf(this.texts.SETTINGS_TEXT),
        appearance: this.tabs.list.indexOf(this.texts.APPEARANCE_TEXT),
        business: this.tabs.list.indexOf(this.texts.BUSINESS_TEXT),
        embedCode: this.tabs.list.indexOf(this.texts.EMBED_CODE_TEXT),
        filters: this.tabs.list.indexOf(this.texts.FILTERS_TEXT)
      }
    },
    widgetConfiguration() {
      return {
        skipConfigFetching: true,
        showCustomer: this.steps.settings.showCustomer.value,
        showAddressDisclamer: this.steps.settings.showAddressDisclamer.value,
        hideReviewAuthor: Boolean(this.steps.settings.hideReviewAuthor.value),
        reviewAuthorStub: this.steps.settings.hideReviewAuthor.value === 'custom' ?
          this.steps.settings.reviewAuthorStub.value :
          '',
        googleApiKey: this.steps.settings.googleApiKey.value,
        googleMapId: this.steps.settings.googleMapId.value.trim(),
        widgetToken: this.content.widget.publicToken,
        filters: this.steps.filters.filters.length ? this.steps.filters.filters : [],
        zoom: this.steps.settings.zoom.value === '' ? null : this.steps.settings.zoom.value,
        dateFormat: this.steps.appearance.dateFormat.value,
        theme: {
          mapHeight: this.steps.settings.mapHeight.value,
          ...Object.keys(this.steps.appearance).reduce((memo, key) => {
            memo[key] = this.steps.appearance[key].value
            return memo
          }, {})
        },
        customMapMarkerIcon: this.steps.appearance.customMapMarkerIcon.value,
        showCustomMapMarkerWrapper: this.steps.appearance.showCustomMapMarkerWrapper.value,
        mapMarkerIcon: this.steps.appearance.mapMarkerIcon.value,
        momentCardLayout: this.steps.appearance.momentCardLayout.value,
        momentCardCssClass: this.steps.appearance.momentCardCssClass.value,
        momentCardBackgroundType: this.steps.appearance.momentCardBackgroundType.value,
        momentCardMediaPosition: this.steps.appearance.momentCardMediaPosition.value,
        momentListLayout: this.steps.appearance.momentListLayout.value,
        momentsPerPage: this.steps.settings.momentsPerPage.value,
        business_ids: this.steps.business.selectedBusinesses
      }
    },
    disabledTabs() {
      return this.isLoading ? this.tabs.list.filter((_tab, index) => index !== this.tabs.activeIndex) : []
    }
  },
  watch: {
    widgetConfiguration() {
      this.isSaved = false
    },
    'widgetConfiguration.business_ids': {
      handler() {
        this.setCategories()
        this.$nextTick(() => {
          this.eraseIncorrectCategories()
        })
      },
      deep: true
    }
  }
}
</script>
