<template>
  <div class="geobooster-widgets-edit-form">
    <Component
      v-for="(group, groupIndex) in appearanceStepLayoutGroups"
      :is="group.layoutType === 'collapse' ? 'ConfiguratorFieldset' : 'div'"
      :key="`${groupIndex}-group`"
      class="geobooster-widgets-edit-form__group">
      <template v-if="group.title" #title>
        {{ group.title }}
      </template>
      <div
        v-for="(line, lineIndex) in group.layout"
        class="geobooster-widgets-edit-form__line"
        :class="{ 'flex-xlg-row': line.length > 1 }" :key="`line${lineIndex}`">
        <Component
          v-for="field in line"
          v-model="field.value"
          :key="field.name"
          :is="field.component"
          :custom-picker-css="getCustomPickerCss(group.layoutType, field.component)"
          :values="field.values"
          :error="field.error"
          :error-text="field.errorText"
          :type="field.type ? field.type : 'text'"
          :label="field.label"
          :name="field.name"
          :disabled="field.disabled"
          :default-value="field.defaultValue"
          :map-marker-color="fields.mapMarkerColor.value"
          :card-background-type="fields.momentCardBackgroundType.value"
          :show-wrapper="fields.showCustomMapMarkerWrapper.value"
          @changeTheme="$emit('changeTheme', $event)"
          @input="field.error = false"
          @blur="$emit('triggerValidate')"
          @setError="$emit('setError', { error: $event.error, errorText: $event.errorText, field })" />
      </div>
    </Component>
  </div>
</template>

<script>
import InfoPanel from 'vue_widgets/components/info_panel'
import CustomInput from 'vue_widgets/components/custom_input'
import CustomSelect from 'vue_widgets/components/custom_select'
import Radio from 'vue_widgets/components/radio_panels'
import CustomCheckbox from 'vue_widgets/components/checkbox'
import ColorPicker from 'vue_widgets/components/color_picker'
import MapMarkerSelect from '../blanks/map_marker_select'
import ThemeSelect from '../blanks/theme_preset_select'
import ConfiguratorFieldset from '../blanks/configurator_fieldset'

export default {
  components: {
    CustomInput,
    CustomSelect,
    Radio,
    CustomCheckbox,
    ColorPicker,
    MapMarkerSelect,
    ThemeSelect,
    InfoPanel,
    ConfiguratorFieldset
  },
  props: {
    fields: {
      type: Object,
      required: true
    }
  },
  methods: {
    getCustomPickerCss(layoutType, componentName) {
      if (layoutType !== 'collapse' || componentName !== 'ColorPicker') return

      return 'left: 69%'
    }
  },
  computed: {
    generalLayout() {
      const layout = []
      layout.push([this.fields.backgroundColor])
      layout.push([this.fields.widgetLayout])
      layout.push([this.fields.momentListLayout])
      layout.push([this.fields.momentCardMediaPosition])

      // TODO: Confirm that we're removing this this entirely
      // layout.push([this.fields.momentCardLayout])
      // if (this.fields.momentCardLayout.value === 'tile') {
      //   layout.push([this.fields.momentCardCssClass])
      // }
      //
      layout.push([this.fields.dateFormat])

      layout.push([this.fields.mapMarkerIcon])

      if (this.fields.mapMarkerIcon.value === 'custom') {
        layout.push([this.fields.showCustomMapMarkerWrapper])
        layout.push([this.fields.customMapMarkerIcon])
      }

      layout.push([this.fields.mapMarkerColor])

      if (this.fields.mapMarkerIcon.value === 'custom') {
        layout.push([this.fields.customMapMarkerWarning])
      }

      layout.push([this.fields.momentCardBackgroundType])

      layout.push([this.fields.themeSelect])

      // this one should probably get removed?
      // layout.push([this.fields.borderColor])

      return layout
    },
    advansedSettingsLayout() {
      const layout = []
      if (this.fields.momentCardBackgroundType.value === 'gradient') {
        layout.push([this.fields.gradientRotation])
        layout.push([this.fields.gradientFirstColor, this.fields.gradientFirstStop])
        layout.push([this.fields.gradientSecondColor, this.fields.gradientSecondStop])
      } else {
        layout.push([this.fields.momentCardBgColor])
      }
      layout.push([this.fields.textColor])
      layout.push([this.fields.categoryTextColor, this.fields.categoryBgColor])
      layout.push([this.fields.customerNameColor])
      layout.push([this.fields.dateColor])
      return layout
    },
    appearanceStepLayoutGroups() {
      return {
        generalLayout: {
          layout: this.generalLayout
        },
        collapseLayout: {
          layoutType: 'collapse',
          title: 'Advanced Settings',
          layout: this.advansedSettingsLayout
        }
      }
    }
  }
}
</script>
