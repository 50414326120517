const COMMON = {
  light: {
    customerNameColor: '#000000',
    momentCardBgColor: '#FFFFFF',
    customerNameColor: '#000000',
    textColor: '#000000',
    dateColor: '#000000',
    categoryTextColor: '#000000',
    categoryBgColor: '#000000'
  },
  dark: {
    customerNameColor: '#FFFFFF',
    momentCardBgColor: '#4B4B4B',
    customerNameColor: '#FFFFFF',
    textColor: '#FFFFFF',
    dateColor: '#FFFFFF',
    categoryTextColor: '#FFFFFF',
    categoryBgColor: '#FFFFFF'
  },
  gradient: {
    gradientRotation: 145,
    gradientFirstStop: 0,
    gradientSecondStop: 81
  }
}

const constructTheme = (themeSettings, options = {}) => {
  const { bgType = 'monotone', themeType = 'light' } = options
  const commonThemeSettings = COMMON[themeType]
  const commonGradient = bgType === 'gradient' ? COMMON.gradient : {}

  return {
    ...commonThemeSettings,
    ...commonGradient,
    ...themeSettings
  }
}

export default {
  data: () => ({
    themes: {
      monotone: {
        light: [
          constructTheme({
            themeName: 'White'
          }),
          constructTheme({
            themeName: 'Light gray',
            momentCardBgColor: '#E0EAFC'
          })
        ],
        dark: [
          constructTheme({
            themeName: 'Dark'
          }, {
            themeType: 'dark'
          })
        ],
        custom: [
          {
            themeName: 'Custom'
          }
        ]
      },
      gradient: {
        light: [
          constructTheme({
            themeName: 'Gradient 1',
            gradientFirstColor: '#FFFDE9',
            gradientSecondColor: '#FFC5EA',
            gradientRotation: 325,
            gradientFirstStop: 18,
            gradientSecondStop: 100
          }, {
            bgType: 'gradient'
          }),
          constructTheme({
            themeName: 'Gradient 2',
            gradientFirstColor: '#FFAFBD',
            gradientSecondColor: '#FFC3A0',
          }, {
            bgType: 'gradient'
          }),
          constructTheme({
            themeName: 'Gradient 3',
            gradientFirstColor: '#74EBD5',
            gradientSecondColor: '#ACB6E5'
          }, {
            bgType: 'gradient'
          })
        ],
        dark: [
          constructTheme({
            themeName: 'Dark gradient 1',
            gradientFirstColor: '#267861',
            gradientSecondColor: '#136A8A',
          }, {
            bgType: 'gradient',
            themeType: 'dark'
          })
        ],
        custom: [
          {
            themeName: 'Custom'
          }
        ]
      }
    }
  })
}
